.dropdown-toggle {
  background: #ffffff !important;
  border: 1px solid #d1d5db !important;
  border-radius: 6px !important;
  width: 306px;
  height: 42px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  color: #6b7280 !important;
  /* text-sm/leading-5/font-normal */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.dropdown-toggle:hover {
  background: #ffffff;
  border-color: #7f56d9 !important;
}
.dropdown-toggle::after {
  content: "+" !important;
  border-top: none !important;
  right: 9px;
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background: #ffffff;
}
.dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  width: 100%;
  padding: 0px !important;
  border-radius: 0 !important;
  border: none !important;
}
.dropdown-item {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87) !important;
}
